//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapGetters} from 'vuex';
import IconArrowProject from '~/assets/img/icons/arrow-project.svg?inline';

export default {
  name: 'UiEventPreview',
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      default: 'table',
    },
    topic: {
      type: String,
      default: 'all',
    },
    width: {
      type: Number,
      default: 414,
    }
  },
  data() {
    return {}
  },
  components: {IconArrowProject},
  computed: {
    ...mapState('layout', ['topics', 'literals', 'windowWidth']),
    ...mapGetters('layout', ['isMobile']),
    title() {
      return this.item?.content?.title ?? ''
    },
    posterPreview() {
      const posterPreview = this.item?.content?.poster_small?.filename
      return posterPreview ? this.item?.content?.poster_small : this.item?.content?.poster_large ?? {}
    },
    topicsOfEvent() {
      const topicsList = this.item?.content?.topics?.map(i => {
        return this.topics.find(j => j.uuid === i)
      }) ?? []
      const titles = topicsList.map(i => (i.name))
      const titlesHtml = topicsList.map(i => (`<span class="${this.topic === i.slug ? 'active' : ''}">${i.name}</span>`))
      return {
        titles: titles.join('/'),
        titlesHtml: titlesHtml.join('/')
      }
    },
    mainTopicColor() {
      const topicsList = this.item?.content?.topics?.map(i => {
        return this.topics.find(j => j.uuid === i)
      }) ?? []
      return topicsList[0]?.content?.theme ?? '#E6827D'
    },
    isLineViewType() {
      return this.viewType === 'line'
    },
  },
  methods: {},
};
