//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapState} from "vuex";
import IconArrow from '~/assets/img/icons/arrow.svg?inline';
import IconEmptySearch from '~/assets/img/icons/empty-search.svg?inline';

export default {
  name: 'UiProjectsTable',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    defaultTopic: {
      type: String,
      required: '',
    },
    isHome: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      viewType: 'table',
      showButtonLeftFlag: false,
      showButtonRightFlag: false,
      isLoading: false,
      isFiltering: false,
      page: 1
    }
  },
  watch: {
    '$route': {
      handler: function () {
        this.isFiltering = true
        this.page = 1
        this.getItems(false, true)
      },
    }
  },
  mounted() {
    document.getElementsByClassName('events-preview__header-wr')[0].addEventListener('scroll', this.onScroll)
    if (this.isMobile) {
      this.onScroll()
    }
    this.getItems(false, false)
  },
  destroyed() {
    document.getElementsByClassName('events-preview__header-wr')[0].removeEventListener('scroll', this.onScroll)
  },
  components: {IconArrow, IconEmptySearch},
  computed: {
    ...mapState('layout', ['topics', 'categories', 'literals']),
    ...mapState('events', ['itemsTotal', 'catalogItems', 'catalogItemsTotal']),
    ...mapGetters('layout', ['isMobile', 'isTablet', 'isDesktop']),
    allTopics() {
      const all = [{
        content: {
          headline: this.literals?.all_button_title ?? ''
        },
        slug: 'all',
        uuid: '',
        name: this.literals?.all_button_title ?? ''
      }]
      return all.concat(this.topics)
    },
    topicsHeadline() {
      return this.blok?.topics_headline ?? ''
    },
    showMoreButton() {
      return this.items.length && this.items.length < this.catalogItemsTotal
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
    isFiltered() {
      const {query, hash} = this.$route;
      return this.topic !== 'all' || hash || query.search
    },
    nothingFoundTitle() {
      const {query, hash} = this.$route;
      const topic = this.topics.find(i => i.slug === query?.topic)?.content?.headline
      const category = this.categories.find(i => i.slug === hash.slice(1))?.content?.headline
      let capitalizedCategory = category
      if (category) {
        const firstLetter = category.charAt(0)
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLetters = category.slice(1)
        capitalizedCategory = firstLetterCap + remainingLetters
      }
      const search = query?.search ?? ''
      const value = search || `${capitalizedCategory ?? ''}${category && topic ? ', ' : ''}${topic ?? ''}`
      return this.literals.nothing_found_title.replace('{value}', value)
    },
    topicData() {
      const {query} = this.$route;
      const queryTopic = query?.topic ?? null
      const topic = this.topics?.find(i => i.slug === queryTopic)
      return topic
    },
    topic() {
      return this.topicData?.slug ?? 'all'
    },
    items() {
      return this.catalogItems ?? []
    }
  },
  methods: {
    ...mapActions('events', ['getEventsItems']),

    handleClick(item) {
      if (this.topic !== item.slug) {
        const search = this.$route?.query?.search ?? ''
        this.$router.push({
          query: {
            search: !!search ? search : undefined,
            topic: item.slug === 'all' ? undefined : item.slug
          },
          hash: this.$route?.hash ?? undefined,
        })
      }
    },

    setViewType(value) {
      this.viewType = value
    },

    onScroll() {
      const item = document.getElementsByClassName('events-preview__header-wr')[0]
      const scrollPosition = item.scrollLeft;
      // this.showButtonLeftFlag = scrollPosition > 0
      // this.showButtonRightFlag = scrollPosition < item.scrollWidth - item.clientWidth
      this.showButtonRightFlag = scrollPosition === 0
    },

    scrollRight() {
      document.getElementsByClassName('events-preview__header-wr')[0].scrollLeft += 20
    },

    scrollLeft() {
      const item = document.getElementsByClassName('events-preview__header-wr')[0]
      const scrollPosition = item.scrollLeft;
      if (scrollPosition >= 20) {
        item.scrollLeft -= 20
      } else {
        item.scrollLeft = 0
      }
    },

    async getItems(updateList, scrollTo) {
      const {hash, query} = this.$route;
      const search = query?.search
      const filter_query = {}
      let sort_by = ''

      if (search) {
        filter_query['title'] = {
          like: `*${search}*`
        }
      }

      if (this.isHome) {
        sort_by = "position:asc"
      }

      if (this.topicData) {
        filter_query['topics'] = {
          any_in_array: this.topicData.uuid
        };
      }

      const category = this.categories.find(i => i.slug === hash.substr(1))
      if (hash) {
        filter_query['categories'] = {
          any_in_array: category.uuid
        };
      }

      try {
        let data = {
          page: this.page,
          filter_query: filter_query,
          sort_by: sort_by,
          per_page: 9,
          updateList: updateList
        }
        return await this.getEventsItems(data);
      } catch (err) {
        console.error({err});
      } finally {
        this.$nextTick(() => {
          this.isLoading = false
          this.isFiltering = false

          if (scrollTo) {
            this.scrollToEvents()
          }
        })
      }
    },
    loadMore() {
      this.isLoading = true
      this.page += 1;
      this.getItems(true, false)
    },
    scrollToEvents() {
      const coursesBody = document.getElementsByClassName('events-preview')[0]
      const offset = coursesBody?.offsetTop

      this.$nextTick(() => {
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        })
      })
    },
  }
};
