import { render, staticRenderFns } from "./ProjectsTable.vue?vue&type=template&id=72758881&scoped=true"
import script from "./ProjectsTable.vue?vue&type=script&lang=js"
export * from "./ProjectsTable.vue?vue&type=script&lang=js"
import style0 from "./ProjectsTable.vue?vue&type=style&index=0&id=72758881&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72758881",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiEventPreviewType: require('/app/components/ui/event/EventPreviewType.vue').default,BaseButton: require('/app/components/base/Button.vue').default,UiWaterfall: require('/app/components/ui/Waterfall.vue').default,UiEventPreview: require('/app/components/ui/event/EventPreview.vue').default,BaseLinkWr: require('/app/components/base/LinkWr.vue').default,BasePreloader: require('/app/components/base/Preloader.vue').default})
