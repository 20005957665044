//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import IconTableView from '~/assets/img/icons/table-view.svg?inline';
import IconLineView from '~/assets/img/icons/line-view.svg?inline';

export default {
  name: 'UiEventPreviewType',
  props: {
    viewType: {
      type: String,
      default: 'table'
    }
  },
  data() {
    return {}
  },
  components: {
    IconTableView,
    IconLineView
  },
  computed: {
    ...mapState('layout', ['literals', 'windowWidth']),
    viewTypes() {
      return [
        {
          icon: 'IconTableView',
          type: 'table'
        },
        {
          icon: 'IconLineView',
          type: 'line'
        }
      ]
    },
    isMobile() {
      return this.windowWidth <= 767
    },
  },
};
