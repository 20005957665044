//
//
//
//
//
//
//
//

import {Waterfall, WaterfallItem} from "vue2-waterfall";

export default {
  name: 'UiWaterfall',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    topic: {
      type: String,
      default: 'table',
    },
  },
  components: {
    Waterfall,
    WaterfallItem,
  },
  data() {
    return {
      options: {
        gutter: 28,
        transitionDuration: 0,
        orizontalOrder: true
      }
    }
  },
}
